import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "../pages/AboutContact";
import Address from "../pages/Address/Address";
import EditAddress from "../pages/Address/EditAddress";
import HomeDelivery from "../pages/Address/HomeDelivery";
import NewAddress from "../pages/Address/NewAddress";
import AddUser from "../pages/addUser";
import BankOffer from "../pages/BankOffer/BankOffer";
import AddOffers from "../pages/CataloguePage/AddOffers";
import AddProduct from "../pages/CataloguePage/AddProduct";
import BulkUpload from "../pages/CataloguePage/BulkUpload";
import CataloguePage from "../pages/CataloguePage/CataloguePage";
import CustomerSupport from "../pages/CustomerSupport/NewCustomerSupport";
import CareerPage from "../pages/Career/CareerPage";
// import WriteToUS from "../pages/CustomerSupport/WriteToUs";
import Cancellation from "../pages/Dashboard/Cancellation";
import Dashboard from "../pages/Dashboard/Dashboard";
import DashboardAddDiscount from "../pages/Dashboard/DashboardAddDiscount";
import DashboardAddHomeSection from "../pages/Dashboard/DashboardAddHomeSection";
import DashboardAddProduct from "../pages/Dashboard/DashboardAddProduct";
import DashboardAddProductCsvFile from "../pages/Dashboard/DashboardAddProductCsvFile";
import DashboardAddShop from "../pages/Dashboard/DashboardAddShop";
import DashboardAlluser from "../pages/Dashboard/DashboardAlluser";
import DashboardDiscount from "../pages/Dashboard/DashboardDiscount";
import DashBoardHome from "../pages/Dashboard/DashBoardHome";
import DashboardHomePage from "../pages/Dashboard/DashboardHomePage";
import DashboardHomeTopBanners from "../pages/Dashboard/DashboardHomeTopBanners";
import DashboardQuiz from "../pages/Dashboard/DashboardQuiz";
import DashboardIFD from "../pages/Dashboard/DashboardIFD";
import DashboardOrders from "../pages/Dashboard/DashboardOrders";
import DashboardProducts from "../pages/Dashboard/DashboardProducts";
import DashboardShop from "../pages/Dashboard/DashBoardShop";
import { AdminNotificationsCreate } from "../pages/Dashboard/NotificationsCreate";
import { AdminNotifications } from "../pages/Dashboard/AdminNotifications";
import Payments from "../pages/Dashboard/Payments";
import Quirys from "../pages/Dashboard/Quirys";
import EditAccont from "../pages/EditAccount/EditAccount";
import UpdateEmail from "../pages/EditAccount/UpdateEmail";
import UpdateNumber from "../pages/EditAccount/UpdateNumber";
import Home from "../pages/Home";
import Login from "../pages/Login";
import DeliveryOptions from "../pages/MyCart/DeliveryOptions";
import MyCart from "../pages/MyCart/MyCart";
import MyOrders from "../pages/MyOrders/MyOrders";
// import OrderSuccess from "../pages/MyOrders/OrderSuccess";
import OrderCancel from "../pages/OrderDetails/OrderCancel";
import OrderDetails from "../pages/OrderDetails/OrderDetails";
import OtpValid from "../pages/OtpValid";
import Payment from "../pages/Payment/Payment";
import StorePickUp from "../pages/Pickup/StorePickUp";
import PrivateRouteCustomer from "../pages/PrivateRoute/PrivateRouteCustomer";
// import Filter from "../pages/ProductCategory/Filter";
// import OfflineProductCategory from "../pages/ProductCategory/OfflineProductCategory";
import ProductCategory from "../pages/ProductCategory/ProductCategory";
import ProductPage from "../pages/ProductPage/ProductPage";
import Profile from "../pages/profile/Profile";
import Signup from "../pages/Signup";
// import StoreFinder from "../pages/StoreFinder/StoreFinder";
import MyWishlist from "../pages/Wishlist/MyWishlist";
import { AdminQueryCatgories } from "../pages/Dashboard/QueryCategories";
import { QueryResponse } from "../pages/Dashboard/QueryResponse";
import { Error404 } from "../components/Error404";
import { Retailer } from "../pages/Retailer";
// import { Refunds } from "../pages/Retailer/Refunds";
import { NewEan } from "../pages/Dashboard/NewEan";
import ThankyouPage from "../pages/ThankyouPage/ThankyouPage";
import TermsAndCondtion from "../pages/TermsAndConditon/TermsAndCondtion";
import ContactUs from "../pages/ContactUs/ContactUs";
import MyRewards from "../pages/MyRewards/myRewards";
import MyProfile from "../pages/MyProfile/MyProfile";
import YourOrder from "../pages/YourOrder/YourOrder";
import TrackingOrder from "../pages/TrackingOrder/trackingOrder";
import AddAddress from "../pages/AddAddress/AddAddress";
import LoginAndSecurity from "../pages/LoginAndSecurity/LoginAndSecurity";
import ChangeTheName from "../pages/ChangeTheName/changeTheName";
import AdminLogin from "../pages/AdminLogin";
import RetailerAdminLogin from "../pages/Retailer/RetailerLogin";
import RetailerDashboard from "../pages/Retailer/RetailerDashboard";
import RetailerHome from "../pages/Retailer/RetailerHome";
import OrderCancelled from "../pages/YourOrder/OrderCancelled";
import { StockTracker } from "../pages/Dashboard/AdminStockTracker/StockTracker";
import AdminRefunds from "../pages/Dashboard/Refunds";
import FindStore from "../pages/findStore/findStore";
import RetailStorePage from "../pages/RetailsStorePage/RetailStorePage";
import DeliveredConsignments from "../pages/Retailer/DeliveredConsignments";
import AdminProductComments from "../pages/Dashboard/AdminProductComments";
import AdminStoreComments from "../pages/Dashboard/AdminStoreComments";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ProductBrands from "../pages/ProductBrands/ProductBrands";
import LocationWiseStores from "../pages/findStore/LocationWiseStores";
import ProductStockTrakerUpdate from "../pages/Dashboard/AdminStockTracker/ProductStockTrakerUpdate";
import ProductStockTrakerAdd from "../pages/Dashboard/AdminStockTracker/ProductStockTrakerAdd";
// import Page1 from "../components/SmartBandhan/Page1";
// import Page2 from "../components/SmartBandhan/Page2";
// import Page3 from "../components/SmartBandhan/Page3";
// import Page4 from "../components/SmartBandhan/Page4";
import SmartBandhanIndex from "../components/SmartBandhan";
import AdminBharatMahotsav from "../pages/Dashboard/AdminBharatMahotsav";
import AdminSmartBandhan from "../pages/Dashboard/AdminSmartBandhan";
// import FourthAnniversaryOfferDetails from "../pages/Dashboard/FourthAnniversaryOfferDetails";
// import FourthAnniversaryOfferDetails from "../pages/Dashboard/FourthAnniversaryOfferDetails";
import IFDHome from "../pages/IFD_Home";
import IFDIndex from "../components/IFD2023";
import Page1 from "../components/IFD2023/Page1";
import Page2 from "../components/IFD2023/Page2";
import Page3 from "../components/IFD2023/Page3";
import OfferReveal from "../components/IFD2023/OfferReveal";
import ClaimPage from "../components/IFD2023/ClaimPage";
import RetailerIFD from "../pages/Retailer/RetailerIFD";
import SpinNWin from "../components/IFD2023/SpinNWin";
import DashboardUpdateProductCsvFile from "../pages/Dashboard/DashboardUpdateProductsCSV";
import Page5 from "../components/IFD2023/Page5";

import AllOffers from "../pages/AllOffers/AllOffers";
import AdminOffers from "../pages/Dashboard/AdminOffers";
import AdminOfferStoreCoupons from "../pages/Dashboard/AdminIFDStoreCoupons";
import AdminOfferStoreIndividualCoupons from "../pages/Dashboard/AdminIFDStoreIndividualCoupons";
import RealmeProPlus from "../pages/AllOffers/RealmeOfferPage";
import TermsAndCondtions from "../pages/Dashboard/TermsAndCondtions";
import AdminAnalytics from "../pages/Dashboard/AdminAnalytics";
import FaqAdmin from "../pages/Dashboard/FaqAdmin";
import AdminSettings from "../pages/Dashboard/AdminSettings";
import HomePageStaticPosters from "../pages/Dashboard/HomePageStaticPosters";
import TaxInvoice from "../components/Invoice/TaxInvoice";
import AddFaq from "../pages/Dashboard/AddFaq";
import FAQ from "../pages/FAQ/FAQ";
import AcceptConsignment from "../components/AcceptConsignment/AcceptConsignment";
import AdminAddSeo from "../pages/Dashboard/AdminAddSeo";
import PreBookingFail from "../pages/PreBookingFail";
import AddSmartPhoneBanner from "../pages/Dashboard/AddSmartPhoneBanner";
import WalletCoupons from "../pages/Dashboard/WalletCoupons";
import UserWallet from "../pages/UserWallet";
const RouterConfig = () => {
  const [storeLocations, setStoreLocations] = useState({
    loaded: false,
    no_of_stores: 0,
    stores: [],
  });

  return (
    <Routes>
      <Route exact path="/not-found" element={<Error404 />} />
      <Route path="/signup" exact element={<Signup />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/otp" exact element={<OtpValid />} />
      <Route path="/adduser" exact element={<AddUser />} />
      <Route path="/" exact element={<Home />} />
      <Route path="/orders" exact element={<MyOrders />} />
      <Route path="/yourOrders" exact element={<YourOrder />} />
      <Route path="/orderCancelled" exact element={<OrderCancelled />} />
      <Route path="/orders/success" exact element={<ThankyouPage />} />
      <Route path="/payment/thankyou" exact element={<ThankyouPage />} />
      <Route path="/terms-condition" exact element={<TermsAndCondtion />} />
      <Route path="/FAQ" exact element={<FAQ />} />
      <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
      <Route path="/career" exact element={<CareerPage />} />
      <Route path="/wallet" exact element={<UserWallet />} />
      <Route path="/consignments/:id" exact element={<AcceptConsignment />} />
      <Route
        path="/mycart"
        exact
        element={
          <PrivateRouteCustomer>
            <MyCart />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/myaddress"
        exact
        element={
          <PrivateRouteCustomer>
            <Address />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/yourAddresses"
        exact
        element={
          <PrivateRouteCustomer>
            <AddAddress />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/loginAndSecurity"
        exact
        element={
          <PrivateRouteCustomer>
            <LoginAndSecurity />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/edit-details"
        exact
        element={
          <PrivateRouteCustomer>
            <ChangeTheName />
          </PrivateRouteCustomer>
        }
      />
      <Route
        // path="/newaddress"
        path="/profile/yourAddresses/newAddress"
        exact
        element={
          <PrivateRouteCustomer>
            <NewAddress />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/editaddress"
        exact
        element={
          <PrivateRouteCustomer>
            <EditAddress />
          </PrivateRouteCustomer>
        }
      />
      <Route path="/payment" exact element={<Payment />} />
      <Route
        path="/profile"
        exact
        element={
          <PrivateRouteCustomer>
            <Profile />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/myProfile"
        exact
        element={
          <PrivateRouteCustomer>
            <MyProfile />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/edit-account"
        exact
        element={
          <PrivateRouteCustomer>
            <EditAccont />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/update-details/number"
        exact
        element={
          <PrivateRouteCustomer>
            <UpdateNumber />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/update-details/email"
        exact
        element={
          <PrivateRouteCustomer>
            <UpdateEmail />
          </PrivateRouteCustomer>
        }
      />
      <Route path="/customer-support" exact element={<CustomerSupport />} />
      <Route path="/write-to-us" exact element={<ContactUs />} />
      <Route
        path="/delivery-option"
        exact
        element={
          <DeliveryOptions />
          // <PrivateRouteCustomer>
          // </PrivateRouteCustomer>
        }
      />
      <Route path="/home-delivery" exact element={<HomeDelivery />} />
      <Route path="/invoice" exact element={<TaxInvoice />} />
      <Route path="/store-pickup" exact element={<StorePickUp storeLocations={storeLocations} setStoreLocations={setStoreLocations} />} />
      {/* <Route path="/store-near-me" exact element={<StoreNear  />} /> */}
      <Route path="/product/Realme-12-Pro-Plus" exact element={<RealmeProPlus />} />
      <Route path="/product/:slug" exact element={<ProductPage />} />
      <Route path="/:category" exact element={<ProductCategory />} />
      <Route path="/:category/f/:slug" exact element={<ProductCategory />} />
      <Route path="/brand/:slug" exact element={<ProductBrands />} />
      {/* <Route path="/:category/filter" exact element={<Filter />} /> */}
      <Route path="/store/:id" exact element={<RetailStorePage />} />
      <Route path="/bank-offer" exact element={<BankOffer />} />
      <Route path="/myRewards" exact element={<MyRewards />} />
      <Route path="/My-Offers" exact element={<AllOffers />} />
      <Route path="/store-finder" exact element={<FindStore storeLocations={storeLocations} setStoreLocations={setStoreLocations} />} />
      <Route path="/store-finder/:location" exact element={<LocationWiseStores storeLocations={storeLocations} />} />
      {/* <Route
                path="/store-finder2"
                exact
                element={
                    <StoreFinder
                        storeLocations={storeLocations}
                        setStoreLocations={setStoreLocations}
                    />
                }
            /> */}
      <Route
        path="/order-details/:id"
        exact
        element={
          <PrivateRouteCustomer>
            <OrderDetails />
          </PrivateRouteCustomer>
        }
      />
      <Route
        path="/trackingOrder/:id"
        exact
        element={
          <PrivateRouteCustomer>
            <TrackingOrder />
          </PrivateRouteCustomer>
        }
      />
      <Route
        exact
        path="/order-cancel/:id"
        element={
          <PrivateRouteCustomer>
            <OrderCancel />
          </PrivateRouteCustomer>
        }
      />
      <Route path="/admin-login" exact element={<AdminLogin />} />

      <Route path="/about-us" exact element={<AboutUs />} />
      <Route path="/wishlist" exact element={<MyWishlist />} />
      <Route path="/prebookFail" exact element={<PreBookingFail />} />

      <Route path="/admin" element={<Dashboard />}>
        <Route exact index element={<DashBoardHome />} />
        <Route exact path="admin-products" element={<DashboardProducts />} />
        <Route exact path="admin-products-ean" element={<NewEan />} />
        <Route exact path="admin-home-page" element={<DashboardHomePage />} />
        <Route exact path="admin-home-page/top-banners" element={<DashboardHomeTopBanners />} />
        <Route exact path="admin-home-page/top-posters" element={<HomePageStaticPosters />} />
        <Route exact path="terms-page" element={<TermsAndCondtions />} />
        <Route exact path="admin-home-add-section" element={<DashboardAddHomeSection />} />
        <Route exact path="admin-home-smartphone-banner" element={<AddSmartPhoneBanner />} />
        <Route exact path="admin-add-product" element={<DashboardAddProduct />} />
        <Route exact path="admin-add-product-csv" element={<DashboardAddProductCsvFile />} />
        <Route exact path="admin-update-product-csv" element={<DashboardUpdateProductCsvFile />} />
        <Route exact path="admin-add-shop" element={<DashboardAddShop />} />
        <Route exact path="admin-discounts" element={<DashboardDiscount />} />
        <Route exact path="admin-add-discount" element={<DashboardAddDiscount />} />
        <Route exact path="admin-orders" element={<DashboardOrders />} />
        <Route exact path="admin-alluser" element={<DashboardAlluser />} />
        <Route exact path="admin-shops" element={<DashboardShop />} />
        <Route exact path="admin-query" element={<Quirys />} />
        <Route exact path="walletCoupoons" element={<WalletCoupons />} />
        <Route exact path="admin-faq" element={<FaqAdmin />} />
        <Route exact path="AddorEditFaq" element={<AddFaq />} />
        <Route exact path="admin-Settings" element={<AdminSettings />} />
        <Route exact path="admin-product-comments" element={<AdminProductComments />} />
        <Route exact path="admin-store-comments" element={<AdminStoreComments />} />
        <Route exact path="admin-query-response/:id" element={<QueryResponse />} />
        <Route exact path="admin-query-categories" element={<AdminQueryCatgories />} />
        <Route exact path="admin-payments" element={<Payments />} />
        <Route exact path="admin-cancellation" element={<Cancellation />} />
        <Route exact path="admin-analytics" element={<AdminAnalytics />} />
        <Route exact path="offer-users" element={<DashboardQuiz />} />
        <Route exact path="ifd-users" element={<DashboardIFD />} />

        <Route path="admin-notifications" element={<AdminNotifications />} />
        <Route path="admin-notifications/:id" element={<AdminNotificationsCreate />} />
        <Route path="refunds" element={<AdminRefunds />} />
        <Route path="stock-tracker">
          <Route exact index element={<StockTracker />} />
          <Route path="edit/:stockId" exact element={<ProductStockTrakerUpdate />} />
          <Route path="add" exact element={<ProductStockTrakerAdd />} />
        </Route>
        {/* <Route path="admin-offers">
          <Route exact index element={<AdminOffers />} />
          <Route exact path="coupons" element={<AdminOfferStoreCoupons />} />
          <Route exact path="coupons/:couponId" element={<AdminOfferStoreIndividualCoupons />} />
        </Route> */}
        <Route path="catelogue-page" element={<CataloguePage />}>
          <Route path="add-product" element={<AddProduct />} />
          <Route path="bulk-upload" element={<BulkUpload />} />
          <Route path="add-offers" element={<AddOffers />} />
        </Route>
        <Route path="AdminAddSeo" element={<AdminAddSeo />} />
      </Route>
      <Route path="/retailer-login" element={<RetailerAdminLogin />} />
      <Route path="/retailer" element={<RetailerDashboard />}>
        <Route index exact element={<RetailerHome />} />
        <Route path="consignments" exact element={<Retailer />} />
        <Route path="delivered-consignments" exact element={<DeliveredConsignments />} />
        {/* <Route path="refunds" exact element={<Refunds />} /> */}
        <Route path="ifd" exact element={<RetailerIFD />} />
      </Route>

      {/* Offers */}
      {/* <Route path="/indian-festival-days" exact element={<IFDHome />} /> */}
      {/* <Route path="/BharatMahotsav" element={<BharatmahostavIndex />}>
        <Route index exact element={<Page1 />} />
        <Route path="2" exact element={<Page2 />} />
        <Route path="3" exact element={<OfferReveal />} />
      </Route> */}

      {/* <Route path="/smartBandhan" element={<SmartBandhanIndex />}>
        <Route index exact element={<Page1 />} />
        <Route path="2" exact element={<Page2 />} />
        <Route path="3" exact element={<Page3 />} />
        <Route path="4" exact element={<Page4 />} />
      </Route> */}

      {/* <Route exact path="admin-fourth-anniversary" element={<FourthAnniversaryOfferDetails />} />
        <Route
          exact
          path="admin-bharat-mahotsav"
          element={<AdminBharatMahotsav />}
        />
        <Route
          exact
          path="admin-smart-bandhan"
          element={<AdminSmartBandhan />}
        /> 
        */}

      {/* <Route path="/quiz-Offer" element={<IFDIndex />}>
        <Route index exact element={<Page1 />} />
        <Route path="2" exact element={<Page2 />} />
        <Route path="3" exact element={<Page3 />} />
        <Route path="4" exact element={<Page5 />} />
      </Route> */}

      {/* route for ifd 2024 */}
      <Route path="/indianfestivaldays2024" element={<IFDIndex />}>
        <Route index exact element={<Page1 />} />{/* this route contain customer info */}
        <Route path="2" exact element={<Page2 />} />{/* this route contain store id validation */}
        <Route path="3" exact element={<Page3 />} />
        <Route path="4" exact element={<SpinNWin />} />
        <Route path="5" exact element={<OfferReveal />} />
        <Route path="6" exact element={<ClaimPage />} />
      </Route>


      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default RouterConfig;
