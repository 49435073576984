import { ListGroup } from "react-bootstrap";
import DashboardLoader from "../DashboardContent/DashboardLoader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const TermsAndConditionModal = (props) => {
  return (
    <>
      <Dialog open={props.show} onClose={props.onHide} scroll={"body"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" className="order-dialog-box">
        <DialogTitle id="scroll-dialog-title d-flex justify-content-between" style={{ alignItems: "center", justifyContent: "space-between", display: "flex" }}>
          Giveaway Terms & Conditions
          <IconButton aria-label="close" className="close-button" onClick={props.onHide}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            overflowY: "initial",
          }}
        >
          <DialogContentText>
            <div>
              <hr />
              <h4>1. Follow our O-LINE-O social media handles:</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>• To enter, you must be following our official O-LINE-O accounts on Instagram / Facebook / Twitter.</ListGroup.Item>
                <ListGroup.Item>• New accounts created solely for the giveaway will not be considered.</ListGroup.Item>
              </ListGroup>
              <h4>2. Tag 5 friends in the comments of this post:</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>• In the comments section of the giveaway advertisement post, tag five (5) of your friends who might be interested.</ListGroup.Item>
                <ListGroup.Item>• Fake or inactive accounts will not be considered valid tags.</ListGroup.Item>
              </ListGroup>
              <h4>3. Share this post in your stories (don't forget to tag us!):</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>• Share this giveaway contest post on your social media story.</ListGroup.Item>
                <ListGroup.Item>• Ensure your account is public so we can verify the share.</ListGroup.Item>
                <ListGroup.Item>• Tag our official O-LINE-O handle in the story for us to track your entry.</ListGroup.Item>
              </ListGroup>
              <h4>4. Visit our website and play the Quiz:</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>• To be eligible to win, you must visit our website and successfully complete the giveaway quiz.</ListGroup.Item>
                <ListGroup.Item>• Multiple quiz attempts are not allowed.</ListGroup.Item>
              </ListGroup>
              <hr />
              <h4>General Rules (applicable to all entries):</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>• This giveaway is open to all tech enthusiasts.</ListGroup.Item>
                <ListGroup.Item>• Employees of O-LINE-O and their immediate families are not eligible to participate.</ListGroup.Item>
                <ListGroup.Item>• The giveaway starts from 24th June 2024 and ends on 30th June 2024</ListGroup.Item>
                <ListGroup.Item>• Winners will be randomly selected from all valid entries after the closing date.</ListGroup.Item>
                <ListGroup.Item>• Winners will be announced on O-LINE-O's social media handles on 1 July 2024.</ListGroup.Item>
                <ListGroup.Item>• To collect the winning prize- winners will have to visit your nearest O-LINE-O store or O-LINE-O headquarter.</ListGroup.Item>
                <ListGroup.Item>• By entering the giveaway, you agree to these terms and conditions and the decisions of O-LINE-O, which are final.</ListGroup.Item>
              </ListGroup>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TermsAndConditionModal;
