import { useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";


const UpdateStoreCoupon = (props) => {

    useEffect(() => {
        console.log("props", props);
    });


    return (
        <div>
          {!props.singleShopData ? (
            <p>Loading...</p>
          ) : (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">IFD 2024 Coupons</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <p className="catalogue_Hint">Shop Brand Id</p>
                        <input className="input-field" type="text" placeholder="Shop Brand Id" defaultValue={props.singleShopData?.brand_store_id} name="brand_store_id" id="Shop Brand Id" />
                        <p className="catalogue_Hint">Shop Name</p>
                        <input className="input-field mt-2" type="text" placeholder="Shop Name" defaultValue={props.singleShopData?.fc_name} name="fc_name" id="" />
                        <p className="catalogue_Hint">All IFD Coupons</p>
                        {props.singleShopData.offers && props.singleShopData.offers.length > 0 ? (
                            props.singleShopData.offers.map((offer) => (
                            <div key={offer._id} className="offer-item">
                                <div className="row">
                                    {/* <div className="col-sm-3"><strong>{offer.offerName === "25,000 Cash Back" ? "iPhone 16" : offer.offerName}</strong></div> */}
                                    <div className="col-sm-3"><strong>{offer.offerName}</strong></div>
                                    <div className="col-sm-4">Total Count: {offer.offerTotalCount}</div>
                                    <div className="col-sm-4">Remaining: {offer.offerRemaining}</div>
                                </div>
                            </div>
                            ))
                        ) : (
                            <p>No offers available</p>
                        )}
                    </form>
                </Modal.Body>
            </Modal>
        )}
        </div>
      );

}

export default UpdateStoreCoupon;